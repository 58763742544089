import './css/generated-base.css'
import 'regenerator-runtime/runtime'
import './defineGlobal'

import { IAppWrapperProps } from './AppWrapper'
import { initalizeSentry } from '@core/sentry'
import { fetchUserSelf, loginUser } from '@core/auth'
import { LazyWrapper } from '@utils/lazyLoader'
import color from '@styles/color'
import React from 'react'
import ReactDOM from 'react-dom'
import { StyledErrorPage } from '@styles/global'

declare global {
  interface Window {
    newFrontendVersionAvailable?: boolean
  }
}

const importAppWrapper = () => import('./AppWrapper')
const AppLoaderLazy = React.lazy(importAppWrapper)
const AppLoader: React.FC<IAppWrapperProps> = (props) => (
  <React.StrictMode>
    <LazyWrapper
      placeholder={
        <span
          className="text-transparent"
          style={{ color: color.whiteTransparent }}
        >
          Loading <span className="font-medium">Ubiquiti </span>
          <span className="font-bold">Community</span>
        </span>
      }
    >
      <AppLoaderLazy {...props} />
    </LazyWrapper>
  </React.StrictMode>
)

const renderApp = async () => {
  importAppWrapper()

  const { user, error } = await fetchUserSelf()

  if (error) {
    ReactDOM.render(
      <StyledErrorPage
        title="Authorization Error"
        description="An unexpected error has occurred, please try again. If this problem occurs again, please contact support."
        actionButtonText="Contact Support"
        heroImageType="access"
        actionButtonProps={{
          onClick: () =>
            (window.location.href = 'https://account.ui.com/supportform'),
        }}
      />,
      document.getElementById('root')
    )

    return
  }

  ReactDOM.render(
    <AppLoader currentUser={user} />,
    document.getElementById('root')
  )
}

initalizeSentry()

try {
  try {
    if (navigator.serviceWorker) {
      navigator.serviceWorker
        .getRegistration('sw.js')
        .then(function (registration) {
          if (registration) {
            registration.unregister()
          }
        })
        .catch((err) => {
          console.error('ServiceWorker Error (async): ', err)
        })
    }
  } catch (err) {
    console.error('ServiceWorker Error (sync): ', err)
  }
  renderApp()
} catch {
  loginUser()
}
