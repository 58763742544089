const ERROR_MESSAGE = 'storage error'

export const localStorageHandler = {
  getItem: (key: string): string | null => {
    try {
      const value = window.localStorage.getItem(key)
      return value
    } catch {
      console.error(ERROR_MESSAGE)
      return null
    }
  },
  removeItem: (key: string): void => {
    try {
      window.localStorage.removeItem(key)
    } catch {
      console.error(ERROR_MESSAGE)
    }
  },
  setItem: (key: string, value: string): void => {
    try {
      window.localStorage.setItem(key, value)
    } catch {
      console.error(ERROR_MESSAGE)
    }
  },
}
